
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
interface DatePickerComponentProps {
    onChange: (
        event: any
    ) => void;
    name: string;
    defaultValue: string,
    label: string;
    isRequired: boolean;
    keyName: string;
    errorStr: string;
}
const DatePickerComponent: React.FC<DatePickerComponentProps> = ({ onChange, name, defaultValue, isRequired, label, keyName,errorStr }) => {
 
    return (
        <div className="mb-3" key={'ele_' + keyName}>
            <label className="form-label" key={'lbk_' + keyName}>{label}{isRequired && <small key={'sml_' + keyName}>*</small>}</label>
                <div>

               
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        className='custom-dt-picker'
                        format="MMM DD, YYYY"
                        name={name}
                        value={dayjs(defaultValue)}
                        views={['year', 'month', 'day']}
                        onChange={onChange}
                    />
                </LocalizationProvider>
                </div>
                {errorStr && <span className="bad-field">{errorStr}</span>}
        </div>
    );
}
export default DatePickerComponent;