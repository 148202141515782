"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Fab, Paper } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import CustomPaginationActionsTable from "../../components/TablePagination";
import StatusRequestData from "../../Models/StatusRequest";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import SwitchComponent from "../../components/SwitchComponent";

const Tenders: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();

  const navigate = useNavigate();
  const columns: any[] = [
    { field: "tenderType", headerName: "Type" },
    { field: "docName", headerName: "Document Name" },
    {
      field: "expiryDate", headerName: "Expiry Date", valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      }
    },
    { field: "notes", headerName: "Document Notes" },
    { field: "creator", headerName: "User Uploaded" },
    {
      field: "status",

      headerName: "Active",
      renderCell: (value: any, row: any) => (
        <SwitchComponent
          checked={value === "1" ? true : false}
          size="small"
          value={value}
          color={"success"}
          inputProps={{ "aria-label": "controlled" }}
          name={'tenderstatuscheck'}
          handleInputChange={() => { }} //already managed by table itself
        />
      ),
    },
    {
      field: "created",
      headerName: "Date Uploaded",
      valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState<any>("");
  const [paginate, setPaginate] = useState<any>({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errorMesssage, setErrorMessage] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);







  useEffect(() => {
    if ((searchQuery || paginate) || isInitialLoad.current) {
      fetchData();
    }
    isInitialLoad.current = false;
  }, [searchQuery, paginate]);


  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.tendersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();

      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };



  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.tendersURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);

        setTotalRecords(response.data.total);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const handlePagination = (paginationData: any) => {
    console.log(paginationData);
    setPaginate(paginationData);
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    setSearchQuery(value);
  };






  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Tender Management"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">

              <Paper sx={{ width: "100%" }} className="mt-4">
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={(row) => {
                    navigate('/management/tenders/' + row.id)
                    //console.log(data);
                  }}
                  paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                  handleStatus={handleStatus}
                />
              </Paper>


            </div>
          </div>
        </section>

        <Fab
          aria-label="add"
          sx={fabStyle}
          onClick={() => {
            navigate('/management/tenders/Add');
          }}
        >
          <Add />
        </Fab>

      </main>
    </div>
  );
};
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default Tenders;
