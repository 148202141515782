"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import { AddCircleOutline, HighlightOff } from "@mui/icons-material";
import DragAndDropFileUpload from "../../components/FileUploadComponent";
import SelectFieldComponent from "../../components/form/SelectFieldComponent";
import TextFieldComponent from "../../components/form/TextFieldComponent";
import TextFieldAreaComponent from "../../components/form/TextFieldAreaComponent";
import IdLabelData from "../../Models/IdLabelData";
import AutoCompleteTextFieldComponent from "../../components/form/AutoCompleteTextFieldComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import SwitchComponent from "../../components/SwitchComponent";
const ResourcesForm: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();


  const [primaryError, setPrimaryError] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [isContractorProgress, setIsContractorProgress] = useState<boolean>(false);
  const [s3FilesProductUpdate, setS3FilesProductUpdate] = useState<any>([]);
  const [s3FilesCommercialUpdate, setS3FilesCommercialUpdate] = useState<any>([]);
  const [s3FilesProduct, setS3FilesProduct] = useState<any>([]);
  const [s3FilesCommercial, setS3FilesCommercial] = useState<any>([]);
  const [s3FilesQuotes, setS3FilesQuotes] = useState<any>([]);
  const [s3FilesQuotesUpdate, setS3FilesQuotesUpdate] = useState<any>([]);
  const [deletedQuoteFiles, setDeletedQuoteFiles] = useState<any>([]);
  const [selectedPrimary, setSelectedPrimary] = useState<any>(null);


  const [totalQuotes, setTotalQuotes] = useState<number>(3);
    const [errorMesssage, setErrorMessage] = useState<any>(null);

  const unitType = [{ id: "", label: "Select Type" }, { id: "Each", label: "Each" }, { id: "Each2", label: "Each2" }, { id: "Each3", label: "Each3" }];

  const [loading, setLoading] = useState(false);

  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [contractorList, setContractorList] = useState<IdLabelData[]>([]);
  const [resourceTypesList, setResourceTypesList] = useState<IdLabelData[]>([])
  const [billableItems, setBillableItems] = useState<IdLabelData[]>([]);
  const [errors, setErrors] = useState({
    name: "",
    unit: "",
    price: "",
    contractorId: "",
    items: "",
    notes: "",
    fileURL: "",
    uploadFile: "",
    expiryDate: "",
    resourceType:""
  });

  const handleAlert = (state: boolean) => {
    setAlertOpen(false);
    setErrorMessage("");
  }
  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!resourceForm.name.trim()) {
      newErrors.name = "Resource Name  is required.";
    }
    if (!resourceForm.unit) {
      newErrors.unit = "Unit is required.";
    }
    if (!resourceForm.resourceType) {
      newErrors.resourceType = "Resource Type is required.";
    }
    if (!resourceForm.price && resourceForm.isQuote === false) {
      newErrors.price = "Price is required.";
    }
    if (!resourceForm.expiryDate) {
      newErrors.expiryDate = "Expiry Date is required.";
    }
    if (!resourceForm.contractorId && resourceForm.isQuote === false) {
      newErrors.contractorId = "Contractor is required.";
    }
    /* if (!resourceForm.items && resourceForm.isQuote === false) {
       newErrors.items = "at least one billable item is required.";
     } */
    // if (!uploadFile) {
    //   newErrors.uploadFile = "File is required.";
    // }
    // Phone Number Validation


    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface resourceFormFields {
    name: string,
    unit: string,
    items: any,
    id?: string | null,
    price: string,

    contractorId: string,
    notes: string,

    expiryDate: string,
    resourceType: string,
    isQuote: boolean

  }

  const [resourceDetailForm, setResourceDetailForm] = useState<any>({});
  const [resourceForm, setResourceForm] = useState<resourceFormFields>({
    name: "",
    unit: "",
    items: null,
    id: null,
    notes: "",
    price: "",
    contractorId: "",
    expiryDate: '',
    isQuote: false,
    resourceType: ''

  });
  const fetchBillables = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.billableURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: '', limit: -1, offset: 0 },
      );
      if (response.success) {
        setBillableItems(response.data);
      } else {

      }
      console.log("GET Response:", response);
    } catch (error) {

      console.error("Error fetching data:", error);
    }
  }
  const fetchResourceTypes = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.resourcesURL + '/resourceTypes',
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: '', limit: -1, offset: 0 },
      );
      if (response.success) {
        setResourceTypesList([
          { id: "", label: "Select Resource Type" },
          ...response.data,
        ]);
        if (response.data.length > 0) {
          if(currentId === 'Add'){
            // setResourceForm((prev: any) => {
            //   return { ...prev, ['resourceType']: response.data[0].name };
            // });
          }
          
        }
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
      console.error("Error fetching data:", error);
    }
  }
  const fetchContractors = async () => {
    try {
      if (isContractorProgress === false) {
        setIsContractorProgress(true);
        const response = await HttpService.get(
          Constants.apiURL + Constants.contractorsURL,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          { search: '', limit: -1, offset: 0 },
        );
        if (response.success) {
          setContractorList([
            { id: "", label: "Select Contractor" }, // Default option
            ...response.data.paginateRecords.map((item: any) => ({
              id: item.id,
              label: item.name,
              })),
          ]);
          setIsContractorProgress(false);
        } else {
          setIsContractorProgress(false);
          setErrorMessage("Failed to Fetch Contractor. Please try again.");
        }
        console.log("GET Response:", response);
      }

    } catch (error) {
      setIsContractorProgress(false);
      setErrorMessage("Failed to Find Contractors. Please try again.");
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {
      setCurrentRecord(null);
    }
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      if (contractorList.length === 0)
        fetchContractors();
      if (billableItems.length === 0)
        fetchBillables();
      if (resourceTypesList.length === 0)
        fetchResourceTypes();
    }
    // console.log(currentId, '<<<<<<<<<<<<<<<<<<<<<<<<-->>>>>>>>>>>>>>>>');
  }, [currentId]);

  const CreateOrUpdate = async (formData: any) => {
    return new Promise(async (resolve) => {
      try {
        const response: any = await HttpService.post(
          Constants.apiURL + Constants.resourcesURL,
          formData,
          { headers: { Authorization: "Bearer " + getAdmin() } }
        );
        if (response.success) {

          resolve(response);
          // fetchData();
          // clearForm(false);
        } else {
          setLoading(false);
          setErrorMessage("Failed to add resource. Please try again.");
          setAlertOpen(true);
          return resolve(null);
        }
        console.log("GET Response:", response);
      } catch (error) {
        setErrorMessage("Failed to add resource. Please try again. Something went Wrong");
        console.error("Error fetching data:", error);
        setAlertOpen(true);
        return resolve(null);
      }
    })

  };


  const getResourceRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.resourcesURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.resource);
      } else {
        resolve(null);
      }
    })
  }
  const getMediaRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.mediaURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.media);
      } else {
        resolve(null);
      }
    })
  }
  const handleDownloadFile = async (ID: any) => {
    const media: any = await getMediaRecord(ID);
    if (media) {
      window.open(media, '_blank');
    }
  }
  const fetchSingleRecord = async (id: any) => {
    try {
      const resource = await getResourceRecord(id);
      if (resource) {
        setCurrentRecord(resource);
        handleRowClick(resource);
      } else {
        setErrorMessage("Failed to add resource. Please try again.");
      }

      console.log("GET Response:", resource);
    } catch (error) {
      setErrorMessage("Failed to add resource. Please try again.");
      console.error("Error fetching data:", error);
    }
  };




  const clearForm = (isForm: boolean) => {

    setResourceForm({
      name: "",
      unit: "",
      items: null,
      id: null,
      notes: "",
      price: "",
     
      contractorId: "",

      expiryDate: '',
      resourceType: '',
      isQuote: false

    });
    setCurrentRecord(null);
    setErrorMessage('');
    setS3FilesCommercial([]);
    setS3FilesProduct([]);
    setS3FilesProductUpdate([]);
    setS3FilesCommercialUpdate([]);
    setLoading(false);
    setS3FilesQuotesUpdate([]);
    setS3FilesQuotes([]);
    setDeletedQuoteFiles([]);
    navigate('/management/resources')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);
    if (currentId) {


      setResourceForm(
        {
          name: record.name,
          unit: record.unit,
          items: record.billable,
          id: record.id,
          notes: record.notes,
          price: record.price,
          contractorId: record.contractorId,
          expiryDate: record.expiryDate,
          resourceType: record.resourceType,
          isQuote: record.isQuote === '1' ? true : false
        }
      );
      if (record.isQuote === '0') {
        if (record.media.length > 0) {
          setS3FilesCommercialUpdate(record.media.filter((mediaData: any) => mediaData.relationType === 'resource_commercial'));
          setS3FilesProductUpdate(record.media.filter((mediaData: any) => mediaData.relationType === 'resource_product'));

        }
      }
      if (record.isQuote === '1') {
        const totalDetailsRecords = record.details;
        setTotalQuotes(totalDetailsRecords.length);
        let detailResources: any = {};
        let mediaRecords: any = [];
        for (var i = 0; i < totalDetailsRecords.length; i++) {
          mediaRecords[i] = [];
          detailResources['price' + i] = totalDetailsRecords[i].price;
          detailResources['contractorId' + i] = totalDetailsRecords[i].contractorId;
          detailResources['notes' + i] = totalDetailsRecords[i].notes;
          detailResources['id' + i] = totalDetailsRecords[i].id;
          mediaRecords[i] = totalDetailsRecords[i].media;
          if (totalDetailsRecords[i].isPrimary === '1') {
            setSelectedPrimary(i);
          }


        }
        // console.log(`mediaRecords`);
        // console.log(mediaRecords);
        // console.log(`mediaRecords`);
        setS3FilesQuotesUpdate(mediaRecords)
        setResourceDetailForm(detailResources);
      }
    }

  };


  const handleFileUpload = async (file: any, relationType: any, relationId: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          setLoading(true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('relationType', relationType);
          formData.append('relationId', relationId);
          if (currentRecord) {
            formData.append('id', currentRecord.id);
          }
          const response = await HttpService.postWithFileUpload(
            Constants.apiURL + Constants.mediaURL,
            formData,
            { headers: { Authorization: "Bearer " + getAdmin(), 'Content-Type': 'multipart/form-data' } }
          );
          if (response.success) {
            resolve(response.media);
          } else {
            setErrorMessage("Failed to add Media. Please try again.");
            setAlertOpen(true);
            resolve(false);
          }
          console.log("GET Response:", response);
        }
      } catch (error) {
        resolve(false);
        setErrorMessage("Failed to add Media. Please try again.");
        setAlertOpen(true);
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    })


  }
  const convertToStructuredArray = (data: any) => {
    const result: any = [];
    const keys = Object.keys(data);

    // Extract unique indexes (e.g., 0, 1, 2) from the keys
    const indexes = Array.from(
      new Set(keys.map((key) => key.match(/\d+$/)?.[0]))
    );

    // Iterate over each index and group related fields into an object
    indexes.forEach((index: any) => {
      console.log(index, selectedPrimary)
      let isPrimary = parseInt(index) === parseInt(selectedPrimary) ? true : false;
      result.push({
        price: data[`price${index}`] || null,
        contractorId: data[`contractorId${index}`] || null,
        notes: data[`notes${index}`] || null,
        isPrimary: isPrimary,
        unit: resourceForm.unit,
        id: data[`id${index}`] || null,
      });
    });

    return result;
  };


  const handleSaveSpecial = async () => {

    try {
      if (validateForm()) {
        if (selectedPrimary === null) {
          setPrimaryError(true);
          return false;
        }

        const details = convertToStructuredArray(resourceDetailForm);

        setLoading(true);
        setPrimaryError(false);

        // console.log(details);
        // return false;
        const postData: any = {
          name: resourceForm.name,
          isQuote: resourceForm.isQuote,
          expiryDate: resourceForm.expiryDate,
          resourceType: resourceForm.resourceType,
          items: resourceForm.items,
          details: details,
          deletedQuoteFiles: deletedQuoteFiles
        }
        if (currentRecord) {
          postData['id'] = currentRecord.id
        }

        let resourceData: any = await CreateOrUpdate(postData);

        if (resourceData) {
          console.log(resourceData);
          const detailsData = resourceData.resource.details;

          if (totalQuotes > 0 && detailsData.length > 0) {
            for (var i = 0; i < detailsData.length; i++) {
              if (s3FilesQuotes[i]) {
                if (s3FilesQuotes[i].length > 0) {
                  for (var j = 0; j < s3FilesQuotes[i].length; j++) {
                    await handleFileUpload(s3FilesQuotes[i][j], 'resource_details', detailsData[i].id);
                  }
                }
              }

            }
          }
          setLoading(false);
          clearForm(true);

        } else {
          setLoading(false);

        }


      }
    } catch (e: any) {

    }


  }
  const handleSave = async () => {
    // console.log(s3FilesCommercial);
    // console.log(s3FilesProduct);
    // return false;
    if (validateForm()) {
      setLoading(true);
      const postData: any = {
        name: resourceForm.name,
        isQuote: resourceForm.isQuote,
        expiryDate: resourceForm.expiryDate,
        resourceType: resourceForm.resourceType,
        items: resourceForm.items,
        details: [{
          contractorId: resourceForm.contractorId,
          price: resourceForm.price,
          unit: resourceForm.unit,
          notes: resourceForm.notes,
          isPrimary: true
        }]
      }
      if (currentRecord) {
        postData['id'] = currentRecord.id;
        postData['s3FilesProduct'] = s3FilesProductUpdate;
        postData['s3FilesCommercial'] = s3FilesCommercialUpdate;
        postData['details'][0]['id'] = currentRecord['details'][0]['id'];
      }
      let resourceData: any = await CreateOrUpdate(postData);
      if (resourceData) {
        if (s3FilesProduct.length > 0) {
          for (var i = 0; i < s3FilesProduct.length; i++) {
            await handleFileUpload(s3FilesProduct[i], 'resource_product', resourceData.resource.id);
          }
        }
        if (s3FilesCommercial.length > 0) {
          for (var j = 0; j < s3FilesCommercial.length; j++) {
            await handleFileUpload(s3FilesCommercial[j], 'resource_commercial', resourceData.resource.id);
          }
        }
      }

      clearForm(false);
      setLoading(false);
    }
  }
  const handleExpiryDate = (value: any) => {
    const expiryDateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
    setResourceForm((prev: any) => {
      return { ...prev, ['expiryDate']: expiryDateValue };
    });
  }
  const removeQuoteFields = () => {
    setTotalQuotes(totalQuotes - 1);
    setResourceDetailForm((prevData: any) => {
      const updatedData = { ...prevData };
      delete updatedData[`notes${totalQuotes - 1}`];
      delete updatedData[`contractorId${totalQuotes - 1}`];
      delete updatedData[`price${totalQuotes - 1}`];
      return updatedData;
    });
  }
  const handleRemove = async (fileData: any, fileType: any) => {
    // const updatedFiles = uploadFile.filter((file: any) => file !== fileData); // Create a new array
    if (fileType === 'resource_product') {
      setS3FilesProduct(fileData);
    }
    if (fileType === 'resource_commercial') {
      setS3FilesCommercial(fileData);
    }

  }
  const handleRemoveFileUpdate = async (fileData: any, fileType: any) => {

    if (fileType === 'resource_product') {
      const updatedFiles = s3FilesProductUpdate.filter((file: any) => file !== fileData); // Create a new array
      setS3FilesProductUpdate(updatedFiles);
    }
    if (fileType === 'resource_commercial') {
      const updatedFiles = s3FilesCommercialUpdate.filter((file: any) => file !== fileData); // Create a new array
      setS3FilesCommercialUpdate(updatedFiles);
    }

  }
  const handleFileChange = async (file: any, fileType: any) => {
    if (file.length > 0) {
      if (fileType === 'resource_product') {
        console.log(`After resource_product`);
        setS3FilesProduct(file);
      }
      if (fileType === 'resource_commercial') {
        console.log(`After resource_commercial`);
        setS3FilesCommercial(file);
      }
    }
  }
  const handleRemoveQuoteUpdate = async (fileData: any, formNumber: any) => {
    const updatedFiles = s3FilesQuotesUpdate[formNumber].filter((file: any) => file !== fileData);
    let removedFiles = deletedQuoteFiles;
    if (removedFiles.indexOf(fileData) === -1) {
      removedFiles.push(fileData);
      setDeletedQuoteFiles(removedFiles);
    }
    setS3FilesQuotesUpdate((prev: any) => {
      return { ...prev, [formNumber]: updatedFiles };
    });

  }
  const handleRemoveQuote = async (fileData: any, formNumber: any) => {
    // const updatedFiles = uploadFile.filter((file: any) => file !== fileData); // Create a new array
    setS3FilesQuotes((prev: any) => {
      return { ...prev, [formNumber]: fileData };
    });

  }
  const handleFileQuoteChange = async (file: any, formNumber: any) => {
    setS3FilesQuotes((prev: any) => {
      return { ...prev, [formNumber]: file };
    });
  }
  const handleBillableChange = (
    e: any, dataValue: any
  ) => {
    setResourceForm((prev: any) => {
      return { ...prev, ['items']: dataValue };
    });

  };
  const handleStatusChange = (
    e: React.ChangeEvent<any>
  ) => { }
  const handleInputChange = (
    e: React.ChangeEvent<any>
  ) => {
    const { name, value, type } = e.target;
    setResourceForm((prev: any) => {
      if (type === 'checkbox') {
        return { ...prev, [name]: e.target.checked };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };
  const handleInputQuoteChange = (
    e: React.ChangeEvent<any>
  ) => {
    const { name, value } = e.target;
    setResourceDetailForm((prevData: any) => ({
      ...prevData,
      [name]: value, // Update state dynamically
    }));
  };
  const renderQuotes = () => {
    const totalElements: any[] = [];
    for (var i = 0; i < totalQuotes; i++) {
      let formNumber = i;
      totalElements.push(
        (<div className="col-4">
          <div >
            <TextFieldComponent
              name={'price' + formNumber}
              value={resourceDetailForm['price' + formNumber] || ''}
              isRequired={true}
              errorStr={''}
              keyName={'resourceDetailFormPrice_' + formNumber}
              label="Price"
              onChange={handleInputQuoteChange}
              placeholder=""
            />
          
            <SelectFieldComponent
              label="Contractor/Supplier"
              name={"contractorId" + formNumber}
              keyName={'resourceDetailFormContractorId_' + formNumber}
              value={resourceDetailForm['contractorId' + formNumber] || ''}
              onChange={(e: any) => {
                handleInputQuoteChange(e);
              }}
              isRequired={true}
              errorStr={''}
              items={contractorList}
            />
            <DragAndDropFileUpload
              errorStr={''}
              label="Attachments" multiple={true}
              isRequired={true}
              keyName={'resourceDetailFormFiles_' + formNumber}
              name={"fileQuoteURL" + formNumber}
              defaultValue={[]}
              isCurrentFiles={resourceForm.id ? s3FilesQuotesUpdate[formNumber] : []}
              handleCurrentFilesRemove={(file: any) => {
                handleRemoveQuoteUpdate(file, formNumber);
              }}
              onChange={(file: any) => {
                handleFileQuoteChange(file, formNumber);
              }}
              handleRemove={(file: any) => {
                handleRemoveQuote(file, formNumber);
              }}
              handleOpen={(id) => {
                handleDownloadFile(id);
              }}
            />

            <TextFieldAreaComponent
              label="Notes"
              isRequired={false}
              name={"notes" + formNumber}
              errorStr={''}
              minRows={7}
              onChange={handleInputQuoteChange}
              placeholder="Enter notes"
              keyName={'resourceDetailFormNotes_' + formNumber}
              value={resourceDetailForm['notes' + formNumber] || ''}
            />

          </div>
          <button
            type="button"
            id={"resetbtn_select"}

            className={formNumber === selectedPrimary ? 'btn btn-primary selectbtn selectedQuote' : 'btn btn-primary selectbtn'}
            onClick={() => {
              setSelectedPrimary(formNumber);
              setPrimaryError(false);
            }}
          >
            {formNumber === selectedPrimary ? 'Selected' : 'Select'}
          </button>
        </div>)
      );
    }

    return totalElements;
  }

  return (
    <div>

      <Header onSearch={() => { }} />

      <SideBar />
      <main id="main" className="main dashboardmain">


        <PageTitle
          title={["Home", "Data Management", "Resource Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">

          <div className="containergrid">
            <div className="mt-2">
              <div className="formElement">
                <div className="row formheader">
                  <div className="col">
                    <h1 className="pageheader">Resource</h1>
                  </div>
                </div>

                <form className="container loginform">
                  <TextFieldComponent
                    name="name"
                    value={resourceForm.name}
                    isRequired={true}
                    errorStr={errors.name}
                    keyName="nameForm"
                    label="Resource Name"
                    onChange={handleInputChange}
                    placeholder=""
                  />
                  <div className="row">
                    <div className="col">
                      <SelectFieldComponent
                        errorStr={errors.unit}
                        isRequired={true}
                        items={unitType}
                        keyName="unitType"
                        label="Unit of Measure"
                        name="unit"
                        value={resourceForm.unit}
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <div className="mb-3">
                        <div className="form-label">Request for quote</div>
                        <div>
                          <SwitchComponent checked={resourceForm.isQuote} name="isQuote" handleInputChange={handleInputChange} value={resourceForm.isQuote} />
                        </div>
                      </div>
                    </div>
                    <div className="col">

                      <DatePickerComponent keyName="expiryDateResource" isRequired={true} label="Expiry Date" errorStr={errors.expiryDate} name="expiryDate" onChange={handleExpiryDate} defaultValue={resourceForm.expiryDate} />

                    </div>
                    <div className="col">
                      <SelectFieldComponent
                        errorStr={errors.resourceType}
                        isRequired={true}
                        items={resourceTypesList}
                        keyName="resourceType"
                        label="Resource Type"
                        name="resourceType"
                        value={resourceForm.resourceType}
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                  </div>
                  {!resourceForm.isQuote && <div>
                    <div className="row">
                      <div className="col-4">
                        <TextFieldComponent
                          name="price"
                          value={resourceForm.price}
                          isRequired={true}
                          errorStr={errors.price}
                          keyName="priceForm"
                          label="Price($)"
                          onChange={handleInputChange}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <SelectFieldComponent
                      label="Contractor/Supplier"
                      name="contractorId"
                      keyName="contractorSelect"
                      value={resourceForm.contractorId ? resourceForm.contractorId.toString() : ''}
                      onChange={(e: any) => {
                        handleInputChange(e);
                      }}
                      isRequired={true}
                      errorStr={errors.contractorId}
                      items={contractorList}
                    />
                    <AutoCompleteTextFieldComponent
                      name="items"
                      value={resourceForm.items}
                      isRequired={false}
                      errorStr={''}
                      keyName="res-billables"
                      label="Billable Items"
                      onChange={handleBillableChange}
                      items={billableItems}
                    />

                    <DragAndDropFileUpload
                      errorStr={errors.uploadFile}
                      label="Product Attachments"
                      multiple={true}
                      isRequired={true}
                      keyName="autoResourceFiles"
                      name="fileURL"
                      defaultValue={resourceForm.id ? s3FilesProduct : []} onChange={(file) => {
                        handleFileChange(file, 'resource_product')
                      }}
                      isCurrentFiles={resourceForm.id ? s3FilesProductUpdate : null}
                      handleRemove={(file: any) => {
                        handleRemove(file, 'resource_product')
                      }}
                      handleCurrentFilesRemove={(file: any) => {
                        handleRemoveFileUpdate(file, 'resource_product')
                      }}
                      handleOpen={(id) => {
                        handleDownloadFile(id);
                      }}
                    />
                    <DragAndDropFileUpload
                      errorStr={''}
                      label="Commercial Attachments"
                      multiple={true}
                      isRequired={false}
                      keyName="autoResourceFilesComm"
                      name="fileURLComm"
                      defaultValue={resourceForm.id ? s3FilesCommercial : []}
                      isCurrentFiles={resourceForm.id ? s3FilesCommercialUpdate : null}
                      onChange={(file: any) => {
                        handleFileChange(file, 'resource_commercial')
                      }}
                      handleRemove={(file: any) => {
                        handleRemove(file, 'resource_commercial')
                      }}
                      handleCurrentFilesRemove={(file: any) => {
                        handleRemoveFileUpdate(file, 'resource_commercial')
                      }}
                      handleOpen={(id) => {
                        handleDownloadFile(id);
                      }}
                    />
                    <TextFieldAreaComponent
                      label="Notes"
                      isRequired={false}
                      name="notes"
                      errorStr={''}
                      minRows={7}
                      onChange={handleInputChange}
                      placeholder="Enter notes"
                      keyName="notesForm"
                      value={resourceForm.notes}
                    />
                  </div>}
                  {resourceForm.isQuote && <div className="row">
                    <div className="col-9">
                      <div className="row">
                        {renderQuotes()}
                        {primaryError && <span className="bad-field mt-3">Select any one from above</span>}
                      </div>
                    </div>


                    <div className="col-3 text-end">

                      <AddCircleOutline style={quoteActionStyle} onClick={() => {
                        setTotalQuotes(totalQuotes + 1);
                      }} />
                      <HighlightOff className="ms-2" style={quoteActionStyle} onClick={() => {
                        removeQuoteFields();
                      }} />

                    </div>
                  </div>}

                  <div
                    className="mb-3 mt-3 txt-center"
                    style={{ textAlign: "left" }}
                  >
                    <button
                      type="button"
                      id={"createbtn"}
                      className="btn btn-primary  createbtn"
                      disabled={loading}
                      onClick={() => {
                        resourceForm.isQuote === false ? handleSave() : handleSaveSpecial();
                      }}
                    >
                      {loading
                        ? "Adding wait..."
                        : currentRecord
                          ? "Update"
                          : "Create"}
                    </button>
                    <button
                      type="button"
                      id={"resetbtn"}
                      className="btn btn-primary loginbtn resetbtn"
                      onClick={() => {
                        clearForm(false);
                      }}
                    >
                      Cancel
                    </button>
                    {errorMesssage && (
                      <p className="text-danger">{errorMesssage}</p>
                    )}

                  </div>

                </form>

              </div>


            </div>

          </div>

        </section>


        <AlertMessageComponent type={'info'} open={alertOpen} handleClose={handleAlert} message={errorMesssage} />
      </main>

    </div>
  );
};


const quoteActionStyle = {
  boxShadow: 'none',
  cursor: 'pointer',
  height: '50px'
};


export default ResourcesForm;
