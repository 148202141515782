import PersonIcon from '@mui/icons-material/Person';

import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PaymentsIcon from '@mui/icons-material/Payments';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';
import FactCheckIcon from '@mui/icons-material/FactCheck';
const Constants = {
  // baseURL: "http://localhost:3000",
 // apiURL: "http://localhost:3000",
  apiURL: 'https://api.loumacpro.com.au',
  usersURL: "/api/v1/users",
  rolesURL: "/api/v1/users/roles",
  contractorsURL: "/api/v1/contractors",
  customersURL: "/api/v1/customers",
  termsURL: "/api/v1/terms",
  adhocTermsURL: "/api/v1/adhoc-terms",
  tendersURL: "/api/v1/tenders",
  resourcesURL: "/api/v1/resources",
  mediaURL: "/api/v1/media",
  billableURL: "/api/v1/billables",
  projectsURL: "/api/v1/projects",
  companyProfileURL: "/api/v1/company-profile",
  refBundleURL: "/api/v1/refbundle",
  USER_POOL_REGION: 'ap-southeast-2',
  REFRESH_TOKEN_URL: `/cognito/refresh-token`,
  CLIENTID: "3n7896ir9vpphef1jjkqmbkh8l",
  DEFAULT_PAGE_SIZE: 25,
  COMMON_SERVER_ERROR: 'Process Failed',
};
export const LoumcMenuItems: any = {
  "management":[{
    label: "Users",
    icon: <PersonIcon className='me-2' />,
    link: '/management/users'
  },
  {
    label: "Contractor",
    icon: <BuildIcon className='me-2' />,
    link: '/management/contractors'
  },
  {
    label: "Customer",
    icon: <PersonIcon className='me-2' />,
    link: '/management/customers'
  },
  {
    label: "Standard T & C’s",
    icon: <DescriptionIcon className='me-2' />,
    link: '/management/terms'
  },
  {
    label: "Adhoc T & C’s",
    icon: <InsertDriveFileIcon className='me-2' />,
    link: '/management/adhoc-terms'
  },
  {
    label: "Tender Documents",
    icon: <FileCopyIcon className='me-2' />,
    link: '/management/tenders'
  },
  {
    label: "Resource Items",
    icon: <BuildIcon className='me-2' />,
    link: '/management/resources'
  },
  {
    label: "Billables",
    icon: <PaymentsIcon className='me-2' />,
    link: '/management/billables'
  },
  {
    label: "Project Templates",
    icon: <AutoAwesomeMosaicIcon className='me-2' />,
    link: '/management/projects'
  },
  {
    label: "Company Profile",
    icon: <PeopleIcon className='me-2' />,
    link: '/management/company-profile'
  },
  {
    label: "Mobile Assets",
    icon: <LocalShippingIcon className='me-2' />,
    link: '/management/contractors'
  },
  {
    label: "Labour",
    icon: <EngineeringIcon className='me-2' />,
    link: '/management/contractors'
  },
  {
    label: "Crews",
    icon: <PeopleIcon className='me-2' />,
    link: '/management/contractors'
  },
  {
    label: "SWMS",
    icon: <ListIcon className='me-2' />,
    link: '/management/contractors'
  },
  {
    label: "Critical Checks",
    icon: <FactCheckIcon className='me-2' />,
    link: '/management/contractors'
  }
]
};
export default Constants;
export const getLocalIDToken = () => {
  return localStorage.getItem('louidtoken');
}

export const setLocalIDToken = (IdToken: any) => {
  localStorage.setItem('louidtoken', IdToken);
}
export const getLocalAccessToken = () => {
  return localStorage.getItem('louaccesstoken');
} 

export const setLocalAccessToken = (IdToken: any) => {
  localStorage.setItem('louaccesstoken', IdToken)
}
export const getLocalRefreshToken = () => {
  return localStorage.getItem('louRefreshToken')
}

export const setLocalRefreshToken = (IdToken: any) => {
  localStorage.setItem('louRefreshToken', IdToken)
}

export const getCurrentDate = () => {
  return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
}
