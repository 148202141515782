
interface ResetButtonComponent {
  onClear: (isClear:boolean
    ) => void;
    keyName: string
  }
  const ResetButtonComponent: React.FC<ResetButtonComponent> = ({ onClear,keyName }) => {
  return (
    <button
    type="button"
    id={keyName}
    key={keyName}
    className="btn btn-primary loginbtn resetbtn"
    onClick={() => {
      onClear(false);
    }}
  >
    Cancel
  </button>
  );
}
export default ResetButtonComponent;