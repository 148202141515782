
interface SelectFieldComponentProps {
  onChange: (
    event: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  errorStr: string;
  items: any[]
}
const SelectFieldComponent: React.FC<SelectFieldComponentProps> = ({ onChange: onChange, label, isRequired, value, keyName, name, errorStr, items }) => {
  return (
    <div className="mb-3" key={'ele_'+keyName}>
      <label className="form-label" key={'lbl_'+keyName}>{label}{isRequired && <small key={'sm_'+keyName}>*</small>}</label>
      <select
        className="form-select"
        id={keyName}
        key={keyName}
        aria-label="Default select example"
        name={name}
        value={value}
        onChange={(e: any) => {
          onChange(e);
        }}
      >
        {items.map((item, index) => (
          <option key={keyName+'_'+index} value={item.id}>{item.label ? item.label : (item.name ? item.name : item.id)}</option>
        ))}
      </select>
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
}
export default SelectFieldComponent;