"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Box,
  Paper,
  Typography
} from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import CustomPaginationActionsTable from "../../components/TablePagination";
import AddButtonComponent from "../../components/form/AddButtonComponent";
import SimpleDialogComponent from "../../components/SimpleDialogComponent";
import QuoteDialogComponent from "./Dialog";

const QuoteDashboard: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Columns definition for the table
  const columns: any[] = [
    { field: "name", headerName: "Quote" },
   
    {
      field: "created",
      headerName: "Date",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "customer",
      headerName: "Customer"
    },
    {
      field: "project",
      headerName: "Project Name"
    },
    {
      field: "status",
      headerName: "Status"
    },
    {
      field: "total",
      headerName: "Total"
    }
  ];

  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Add New Quote");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [paginate, setPaginate] = useState({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });

  // Fetch terms list
  const fetchData = async () => {
    try {
     
        setRows([{
          name: "154-24",
          created: "29-11-2024" ,
          customer:"K&G Distributors",
          project:"Y&Co Distributors",
          status:"Accepted",
          total:"$ 100,500"
          
        },
        {
          name: "154-26",
          created: "29-11-2024" ,
          customer:"Alpha Beta",
          project:"Graphic Design",
          status:"Request for Info",
          total:"$ 272,000"
          
        }]);
        setTotalRecords(2);
     
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  


  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);
  // Handle status toggle
  const handleStatus = async (row: any) => {
    const newStatus = row.status === "1" ? "0" : "1";
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.termsURL,
        { status: newStatus, id: row.id },
        { headers: { Authorization: `Bearer ${getAdmin()}` } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to update status. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to update status. Please try again.");
    }
  };
const dialogContent = ()=>{

}
  // Clear form and reset state
  const handlePagination = (paginationData: any) => setPaginate(paginationData);
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);
  return (
    <div>
      <Header onSearch={handleSearchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home",  "Quote Dashboard"]}
          links={["/dashboard", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
                <Paper sx={{ width: "100%" }} className="mt-4">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:'10px', mb: 2 }}>
                  <Typography variant="h6">All Quotes</Typography> 
                  <AddButtonComponent onClick={() => {
                    setDialogOpen(true);
                  }} keyName="quoteaddicon" label="Add New" />
                </Box>
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) =>()=>{

                    }}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
            </div>
          </div>
          {<QuoteDialogComponent  open={dialogOpen} handleClose={() => {
            setDialogOpen(false)
          }} />}
        </section>
       
      </main>
    </div>
  );
};

export default QuoteDashboard;
