import { TextareaAutosize } from "@mui/material";

interface TextFieldAreaComponentProps {
  onChange: (
    event: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  placeholder?: string;
  errorStr: string;
  minRows:number;
}
const TextFieldAreaComponent: React.FC<TextFieldAreaComponentProps> = ({ onChange: onChange, label, isRequired, value, keyName, name, placeholder, errorStr,minRows }) => {
  return (
    <div className="mb-3" key={'mainele_'+keyName}>
      <label key={'lbl_'+keyName} className="form-label">{label}{isRequired && <small>*</small>}</label>
      <TextareaAutosize
        className="form-control form-control-loum"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        minRows={minRows}
        key={keyName}
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
}
export default TextFieldAreaComponent;