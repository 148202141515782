import { Checkbox, FormControlLabel } from "@mui/material";

interface RadioCheckBoxFieldComponentProps {
  onCheckBoxChange: (
    event: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  errorStr: string;
  items: any[];
  checkField: any
}
const RadioCheckBoxFieldComponent: React.FC<RadioCheckBoxFieldComponentProps> = ({onCheckBoxChange, checkField, label, items, isRequired, keyName, name, errorStr }) => {
  return (
    <div className="row" key={keyName}>
      <div className="col">
        <label className="form-label">{label} {isRequired && <small>*</small>}</label>
        <div className="form-check">
          {items.map((item: any, index: any) => (
            <div className="roleEle" key={'chkele_'+index}>
              <FormControlLabel
                style={checkStyle}
                id={item.id + "_lbb" + index}
                key={item.id + "_" + index}
                control={
                  <Checkbox
                  key={'chk_'+keyName+'_'+index}
                    name={name}
                    onChange={onCheckBoxChange}
                    checked={checkField == item.id}
                    value={item.id}
                    style={checkStyle}
                  />
                }
                label={item.label}
              />
            </div>
          ))}

        </div>
        {errorStr && <span className="bad-field">{errorStr}</span>}
      </div>
    </div>
  );
}
export default RadioCheckBoxFieldComponent;

const checkStyle = {
  fontSize: "13px",
};