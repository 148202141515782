interface ButtonComponentProps {
  onClick: (
  ) => void;
  label: string;
  errors: any;
  currentRecord?: any;
  keyName: string
}
const ButtonComponent: React.FC<ButtonComponentProps> = ({ onClick, label, keyName }) => {
  return (
    <button
      type="button"
      id={keyName}
      key={keyName}
      className={"btn btn-primary  btn-loumac"}

      onClick={() => {
        onClick()
      }}
    >
      {label}
    </button>
  );
}
export default ButtonComponent;