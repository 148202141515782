"use client";
import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Constants, { getCurrentDate } from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import DragAndDropFileUpload from "../../components/FileUploadComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
import SelectFieldComponent from "../../components/form/SelectFieldComponent";
import TextFieldComponent from "../../components/form/TextFieldComponent";
import TextFieldAreaComponent from "../../components/form/TextFieldAreaComponent";
const TendersForm: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();



  const [uploadFile, setUploadFile] = useState<any>("");

  const [errorMesssage, setErrorMessage] = useState<any>(null);

  const tenderType = [{ id: "", label: "Select Document Type" }, { id: "Insurances", label: "Insurances" }, { id: "Capability Statement", label: "Capability Statement" }, { id: "Certificate and Accreditations", label: "Certificate and Accreditations" }];
  const [loading, setLoading] = useState(false);

  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [errors, setErrors] = useState({
    tenderType: "",
    docName: "",
    expiryDate: "",
    fileURL: "",
    uploadFile: ""
  });

  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!tenderForm.tenderType.trim()) {
      newErrors.tenderType = "Type  is required.";
    }
    if (!tenderForm.docName) {
      newErrors.docName = "Document Name is required.";
    }

    if (!tenderForm.expiryDate) {
      newErrors.expiryDate = "Expiry Date  is required.";
    }
    if (!uploadFile) {
      newErrors.uploadFile = "File  is required.";
    }
    // Phone Number Validation

    // if (!tenderForm.fileURL) {
    //   newErrors.fileURL = "File is required.";
    // }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface tenderFormFields {
    tenderType: string,
    docName: string,
    expiryDate: string,
    id?: string | null,
    notes: string,
    fileURL: string,
    fileName?: string

  }
  const [tenderForm, setTenderForm] = useState<tenderFormFields>({
    tenderType: "Insurances",
    docName: "",
    expiryDate: getCurrentDate(),
    id: null,
    notes: "",
    fileURL: "",
    fileName: ""

  });

  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {

      setCurrentRecord(null);
    }
  }, [currentId]);

  const CreateOrUpdate = async (formData: any) => {
    try {
      const response = await HttpService.postWithFileUpload(
        Constants.apiURL + Constants.tendersURL,
        formData,
        { headers: { Authorization: "Bearer " + getAdmin(), 'Content-Type': 'multipart/form-data' } }
      );
      if (response.success) {

        clearForm(false);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };




  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.tendersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.tender);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };

  const clearForm = (isForm: boolean) => {

    setTenderForm({
      expiryDate: "",
      docName: "",
      fileURL: "",
      id: null,
      notes: "",
      tenderType: ''

    });
    setCurrentRecord(null);
    setErrorMessage('');
    navigate('/management/tenders')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);

    setTenderForm(
      {
        expiryDate: record.expiryDate,
        docName: record.docName,
        fileURL: record.fileURL,
        id: record.id,
        notes: record.notes,
        tenderType: record.tenderType,
        fileName: record.fileName

      }
    );

  };


  const handleSave = async () => {

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('expiryDate', tenderForm.expiryDate);
      formData.append('docName', tenderForm.docName);
      formData.append('notes', tenderForm.notes);
      formData.append('tenderType', tenderForm.tenderType);
      if (currentRecord) {
        formData.append('id', currentRecord.id);

      }
      await CreateOrUpdate(formData);
      setLoading(false);
    }
  }
  const handleExpiryDate = (value: any) => {
    const expiryDateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
    setTenderForm((prev: any) => {
      return { ...prev, ['expiryDate']: expiryDateValue };
    });
  }
  const handleRemove = (file: any) => {
    setUploadFile(null);
  }
  const handleFileChange = (file: any) => {
    setUploadFile(file[0]);
  }
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;
    setTenderForm((prev: any) => {
      // Check if the input is a checkbox
      if (type === "checkbox") {
        const target: any = e.target;
        const currentValues = prev[name] || []; // Get the current array or initialize it
        if (target.checked && currentValues.includes(value) === false) {
          return { ...prev, [name]: [...currentValues, value] };
        } else {
          return { ...prev, [name]: currentValues.filter((id: any) => id !== value) };
        }
      } else {
        console.log(value);
        // Handle other input types
        return { ...prev, [name]: value };
      }
    });
  };

  return (
    <div>
      <Header onSearch={() => { }} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Tender Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">


              <div className="formElement">
                <div className="row formheader">
                  <div className="col">
                    <h1 className="pageheader">Document</h1>
                  </div>
                </div>

                <form className="container loginform">
                  <SelectFieldComponent
                    errorStr={errors.tenderType}
                    isRequired={true}
                    items={tenderType}
                    keyName="tenderType"
                    label="Type"
                    name="tenderType"
                    value={tenderForm.tenderType}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                  />
                  <TextFieldComponent
                    name="docName"
                    value={tenderForm.docName}
                    isRequired={true}
                    errorStr={errors.docName}
                    keyName="docNameForm"
                    label="Document Name"
                    onChange={handleInputChange}
                    placeholder=""
                  />
                  <DatePickerComponent keyName="expiryDateTender" isRequired={true} label="Expiry Date" errorStr={errors.expiryDate} name="expiryDate" onChange={handleExpiryDate} defaultValue={tenderForm.expiryDate} />
                  <TextFieldAreaComponent
                    label="Notes"
                    isRequired={false}
                    name="notes"
                    errorStr={''}
                    minRows={7}
                    onChange={handleInputChange}
                    placeholder="Enter notes"
                    keyName="notesForm"
                    value={tenderForm.notes}


                  />
                  <DragAndDropFileUpload
                    handleRemove={handleRemove}
                    handleCurrentFilesRemove={handleRemove}
                    errorStr={errors.uploadFile} multiple={false}
                    label="Attachment" isRequired={true} keyName="fileTenderForm"
                    name="fileURL"
                    defaultValue={tenderForm.id ? [{ name: tenderForm.fileName, file: tenderForm.fileURL }] : []}
                    onChange={handleFileChange} />
                  <div
                    className="mb-3 mt-3 txt-center"
                    style={{ textAlign: "left" }}
                  >
                    <button
                      type="button"
                      id={"createbtn"}
                      className="btn btn-primary  createbtn"
                      disabled={loading}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {loading
                        ? "Adding wait..."
                        : currentRecord
                          ? "Update Document"
                          : "Create Document"}
                    </button>
                    <button
                      type="button"
                      id={"resetbtn"}
                      className="btn btn-primary loginbtn resetbtn"
                      onClick={() => {
                        clearForm(false);
                      }}
                    >
                      Cancel
                    </button>
                    {errorMesssage && (
                      <p className="text-danger">{errorMesssage}</p>
                    )}
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>

      </main>
    </div>
  );
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};
const uploadStyle = {
  width: '100%',
  height: '200px',
  color: '#464A53',
  background: 'transparent',
  border: '1px solid #DDDFE1'
}
const errorStyle = {
  color: "red",
};
export default TendersForm;
