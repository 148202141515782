import { Autocomplete, TextField } from "@mui/material";
interface AutoCompleteTextFieldComponentProps {
  onChange: (
    event: any,
    value: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: any;
  placeholder?: string;
  errorStr: string;

  items: any[]

}
const AutoCompleteTextFieldComponent: React.FC<AutoCompleteTextFieldComponentProps> = ({ onChange: onChange, label, isRequired, value, keyName, name, items, errorStr }) => {
  return (
    <div className="mb-3" key={'ele_' + keyName}>
      <label className="form-label" key={'lbk_' + keyName}>{label}{isRequired && <small key={'sml_' + keyName}>*</small>}</label>
      <Autocomplete
        multiple
        id={'aut_' + keyName}
        disableClearable
        options={items}
        onChange={(e: any, value: any) => {
          onChange(e, value);
        }}
        getOptionLabel={(option) => option.label}
        value={value ? value : []}
        // onChange={onChange}
        renderInput={(params) => (
          <TextField

            hiddenLabel
            {...params}
            label={''}
            name={name}
            value={value}
            id={'txt_' + keyName}

            slotProps={{
              input: {
                ...params.InputProps,
                type: 'search',
              },
            }}
          />
        )}
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
}
export default AutoCompleteTextFieldComponent;