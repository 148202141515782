
interface TextFieldComponentProps {
  onChange: (
    event: any
  ) => void;
  label: string;
  keyName: string;
  name: string;
  isRequired: boolean;
  value: string;
  placeholder?: string;
  errorStr: string;
  disabled?:boolean;
  style?:any;
  type?:string;
  
}
const TextFieldComponent: React.FC<TextFieldComponentProps> = ({ onChange: onChange, label, isRequired, value, keyName, name, placeholder, errorStr,disabled,style,type }) => {
  return (
    <div className="mb-3" key={'ele_'+keyName}>
      <label className="form-label" key={'lbk_'+keyName}>{label}{isRequired && <small key={'sml_'+keyName}>*</small>}</label>
      <input
        type={type ? type :  "text"}
        className="form-control form-control-loum"
        id={keyName}
        name={name}
        
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
        disabled={disabled}
      />
      {errorStr && <span className="bad-field">{errorStr}</span>}
    </div>
  );
}
export default TextFieldComponent;