"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Constants, { getCurrentDate } from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButtonComponent from "../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../components/form/ResetButtonComponent";
import TextFieldComponent from "../../components/form/TextFieldComponent";
import DragAndDropFileUpload from "../../components/FileUploadComponent";
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DatePickerComponent from "../../components/DatePickerComponent";

import AlertMessageComponent from "../../components/AlertMessageComponent";
const CompanyProfile: React.FC = () => {
  const { getAdmin } = useStore();
 
  const navigate = useNavigate();
  // State variables
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [s3FileCurrent, setS3FileCurrent] = useState<any>(null);
  const [s3FileCurrentRemoved, setS3FileCurrentRemoved] = useState<any>(null);
  const [logoFile, setLogoFile] = useState<any>(null);
  const [financialRecords, setFinancialRecords] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
    
  const [companyForm, setCompanyForm] = useState(
    {
      companyName: "",
      abn: "",
      address: "",
      contactNumber: "",
      email: "",
      
      id: null
    }
  );
  const [errors, setErrors] = useState({
    companyName: "",
    abn: "",
    address: "",
    contactNumber: "",
    email: "",
  });



  // Fetch single term record
  const fetchSingleRecord = async () => {
    try {
      const response = await HttpService.get(
        `${Constants.apiURL + Constants.companyProfileURL}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.data) {
        handleRowClick(response.data);
      } else {
        //setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      //setErrorMessage("Failed to fetch record. Please try again.");
    }
  };
  // Fetch data when component loads or dependencies change
  useEffect(() => {
    fetchSingleRecord();
  }, []);




  // Handle form input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCompanyForm((prevData) => ({ ...prevData, [name]: value }));
    validateField(e.target);

  };
  const handleInputChangeFinancial = (e: React.ChangeEvent<HTMLTextAreaElement>,keyName: string,index: number) => {
    const { name, value } = e.target;
    setFinancialRecords((prevData: any[]) => {
      const updatedData = [...prevData]; // Create a shallow copy of the array
      if (updatedData[index]) {
        updatedData[index] = { 
          ...updatedData[index], // Spread the existing object
          [keyName]: value,      // Dynamically update the property
        };
      }
      return updatedData;
    });

  };
  const handleFinancialDate = (value: any,keyName: string,index: number) => {
    const expiryDateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
    setFinancialRecords((prevData: any[]) => {
      const updatedData = [...prevData]; // Create a shallow copy of the array
      if (updatedData[index]) {
        updatedData[index] = { 
          ...updatedData[index], // Spread the existing object
          [keyName]: value,      // Dynamically update the property
        };
      }
      return updatedData;
    });
  }
  const validateField = async (element: any) => {
    const newErrors: any = {};
    if (!element.value.trim()) {
      newErrors[element.name] = "this is required field";
      if(element.name === 'abn'){
        const abnRegex = /^\d{11}$/;
        if (!abnRegex.test(element.value) && element.value) {
          newErrors.abn = "Invalid ABN. Must be 11 digits.";
        }
      }
      if(element.name === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        }
      }
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }

  // Handle record creation or update
  const handleSave = async () => {
    setLoading(true);
    const reqData: any = companyForm;
    reqData['financialRecords'] = financialRecords;
    if(s3FileCurrentRemoved) {
      reqData['s3FileCurrentRemoved'] = s3FileCurrentRemoved;
    }
    const endpoint =
      `${Constants.apiURL + Constants.companyProfileURL}`
    let response: any;
    try {
        response = await HttpService.post(endpoint, reqData, {
          headers: { Authorization: `Bearer ${getAdmin()}` },
        });
      if (response.success) {
        if(logoFile)
          await handleFileUpload(logoFile[0],'company_profile',response.company.id);
        clearForm();
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    } finally {
      setLoading(false);
    }
  };


  const handleFileUpload = async (file: any, relationType: any, relationId: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          setLoading(true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('relationType', relationType);
          formData.append('relationId', relationId);
          if (currentRecord) {
            formData.append('id', currentRecord.id);
          }
          const response = await HttpService.postWithFileUpload(
            Constants.apiURL + Constants.mediaURL,
            formData,
            { headers: { Authorization: "Bearer " + getAdmin(), 'Content-Type': 'multipart/form-data' } }
          );
          if (response.success) {
            resolve(response.media);
          } else {
            setErrorMessage("Failed to add Media. Please try again.");
            setAlertOpen(true);
            resolve(false);
          }
          console.log("GET Response:", response);
        }
      } catch (error) {
        resolve(false);
        setErrorMessage("Failed to add Media. Please try again.");
        setAlertOpen(true);
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    })


  }
  // Clear form and reset state
  const clearForm = () => {
setAlertOpen(true);
setErrorMessage("Success");
   window.location.reload();
  };
  const handleFileChange = async (file: any) => {
    setLogoFile(file);
  }
  const handleDownloadFile = async (ID: any) => {
    const media: any = await getMediaRecord(ID);
    if (media) {
      window.open(media, '_blank');
    }
  }
  const getMediaRecord = async (id: any) => {
    return new Promise(async (resolve, reject) => {
      const response = await HttpService.get(
        Constants.apiURL + Constants.mediaURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {
        resolve(response.media);
      } else {
        resolve(null);
      }
    })
  }
  const handleAlert = (state: boolean) => {
    setAlertOpen(false);
    setErrorMessage("");
  }
  const handleRemove = async (fileData: any) => {
    // const updatedFiles = uploadFile.filter((file: any) => file !== fileData); // Create a new array
    setLogoFile(null);

  }
  const handleCurrentFilesRemove = async (fileData: any) => {
    // const updatedFiles = uploadFile.filter((file: any) => file !== fileData); // Create a new array
    setS3FileCurrent(null);
    setS3FileCurrentRemoved(fileData);
    

  }
  const AddFinancial = async () => {
    // const updatedFiles = uploadFile.filter((file: any) => file !== fileData); // Create a new array
    setFinancialRecords((prevRecords) => [...prevRecords, {
      overhead:'',
      forecast:'',
      overheadPercent:'',
      effectiveDate:getCurrentDate(),
      expiryDate:getCurrentDate(),
      reviewDate: getCurrentDate()
    }]);

  }
  
  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setCompanyForm(
      {
        companyName: record.companyName,
        abn: record.abn,
        address: record.address,
        contactNumber: record.contactNumber,
        email: record.email,
        id: record.id
      }
    );
    setS3FileCurrent([record.media]);
    setFinancialRecords(record.financial);

  };


  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <div>
      <Header onSearch={handleSearchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Company Profile"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">

            </div>
            <div className="formElement">
              <div className="row formheader">
                <div className="col">
                  <h1 className="pageheader">Company Profile</h1>
                </div>
              </div>
              <form className="container loginform">
                <DragAndDropFileUpload
                  errorStr={''}
                  label="Logo"
                  multiple={false}
                  customHeight="65px"
                  isRequired={false}
                  keyName="autoResourceFilesComm"
                  name="fileURLComm"
                  defaultValue={logoFile ?? []}
                  isCurrentFiles={companyForm.id ? s3FileCurrent : null}
                  onChange={(file: any) => {
                    handleFileChange(file)
                  }}
                  handleRemove={(file: any) => {
                    handleRemove(file)
                  }}
                  handleCurrentFilesRemove={(file: any) => {
                    handleCurrentFilesRemove(file)
                  }}
                  handleOpen={(id) => {
                    handleDownloadFile(id);
                  }}
                />
                <TextFieldComponent
                  label="Company Name"
                  keyName="companyNameFormField"
                  name="companyName"
                  value={companyForm.companyName}
                  onChange={handleInputChange}
                  errorStr={errors.companyName}
                  isRequired={true}
                />
                <TextFieldComponent
                  label="ABN"
                  keyName="abnFormField"
                  name="abn"
                  value={companyForm.abn}
                  onChange={handleInputChange}
                  errorStr={errors.abn}
                  isRequired={true}
                />
                <TextFieldComponent
                  label="Address"
                  keyName="address"
                  name="address"
                  value={companyForm.address}
                  onChange={handleInputChange}
                  errorStr={errors.address}
                  isRequired={true}
                />
                <TextFieldComponent
                  label="Email ID"
                  keyName="emailFormField"
                  name="email"
                  value={companyForm.email}
                  onChange={handleInputChange}
                  errorStr={errors.email}
                  placeholder="yourname@company.com"
                  isRequired={true}
                />
                <TextFieldComponent
                  label="Contact Number"
                  keyName="contactNumberField"
                  name="contactNumber"
                  value={companyForm.contactNumber}
                  onChange={handleInputChange}
                  errorStr={errors.contactNumber}
                  placeholder=""
                  isRequired={true}
                />
                <div className="mt-2 mb-2">
                  <label className="form-label" key={'lbk_financial'}><b>Financial</b></label>
                  <TableContainer className="mt-3">
                  <Table sx={{ '& > *': { borderRight: '1px solid #ddd', borderTop: '1px solid #ddd' } }}>
                    <TableBody key="tblbody_fin">
                      <TableRow key={'headrow_' } sx={{ '& > *': { borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' } }}>
                        <TableCell key={'tblheadclldesc_'} className="boldfont" style={{width: '130px'}}>Overhead ($)</TableCell>
                        <TableCell key={'tblheadcllitemc_'} className="boldfont" style={{width: '150px'}}>Forecast Revenue</TableCell>
                        <TableCell key={'tblbotblheadclliqty_'} className="boldfont" style={{width: '130px'}}>Overhead (%)</TableCell>
                        <TableCell key={'tblheadcllcost_'} className="boldfont"style={{width: '150px'}} >Effective Date</TableCell>
                        <TableCell key={'tblheadcluom_'} className="boldfont" style={{width: '150px'}}>Expiry Date</TableCell>
                        <TableCell key={'tblheadclttl_'} className="boldfont" style={{width: '150px'}}>Review Date</TableCell>
                      </TableRow>
                      {financialRecords.map((fRecord: any,index: number)=>{
                     return (  <TableRow key={'headrow_' } sx={{ '& > *': { borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' } }}>
                        <TableCell key={'tblheadclldesc_'} >
                            <TextFieldComponent
                            label=""
                            keyName={"overhead_single_"+index}
                            name={"overhead_single_"+index}
                            value={fRecord.overhead}
                            type="number"
                            onChange={(e)=>{
                              handleInputChangeFinancial(e, 'overhead',index)
                            }}
                            errorStr={''}
                            placeholder=""
                            isRequired={false}
                          />
                        </TableCell>
                        <TableCell key={'tblfin_forcst'} >
                        <TextFieldComponent
                            label=""
                            keyName={"forecast_single_"+index}
                            name={"forecast_single_"+index}
                             type="number"
                            value={fRecord.forecast}
                            onChange={(e)=>{
                              handleInputChangeFinancial(e,'forecast', index)
                            }}
                            errorStr={''}
                            placeholder=""
                            isRequired={false}
                          />
                        </TableCell>
                        <TableCell key={'tblbotblheadclliqty_'+index}>
                        <TextFieldComponent
                            label=""
                            keyName={"overheadPercent_single_"+index}
                            name={"overheadPercent_single_"+index}
                             type="number"
                            value={fRecord.overheadPercent}
                            onChange={(e)=>{
                              handleInputChangeFinancial(e,'overheadPercent', index)
                            }}
                            errorStr={''}
                            placeholder=""
                            isRequired={false}
                          />
                        </TableCell>
                        <TableCell key={'tblheadclleffectiveDate_'+index} >
                        <DatePickerComponent
                        keyName={"effectiveDate_single"+index}
                        isRequired={false} 
                        label="" 
                        errorStr={''} 
                        name={"effectiveDate_single"+index}
                        onChange={(value)=>{
                          handleFinancialDate(value,'effectiveDate',index)
                        }}
                        defaultValue={fRecord.effectiveDate ?fRecord.effectiveDate : getCurrentDate()} />
                        </TableCell>
                        <TableCell key={'tblheadcluom_'+index} >
                        <DatePickerComponent
                        keyName={"expiryDate_single"+index}
                        isRequired={false} 
                        label="" 
                        errorStr={''} 
                        name={"expiryDate_single"+index} 
                        onChange={(value)=>{
                          handleFinancialDate(value,'expiryDate', index)
                        }}
                        defaultValue={fRecord.expiryDate ? fRecord.expiryDate : getCurrentDate()} />
                        </TableCell>
                        <TableCell key={'tblheadclttl_'+index}  style={{width: '130px'}} >
                        <DatePickerComponent
                        keyName={"reviewDate_single"+index}
                        isRequired={false} 
                        label="" 
                        errorStr={''} 
                        name={"reviewDate_single"+index} 
                        onChange={(value)=>{
                          handleFinancialDate(value, 'reviewDate',index)
                        }}
                        defaultValue={fRecord.reviewDate ? fRecord.reviewDate : getCurrentDate()} />
                        </TableCell>
                        
                      </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
                  
                <div className="mb-3 mt-3">
                  <Button variant="outlined" startIcon={<AddIcon />} className="primarybtn" onClick={() => {
                    AddFinancial();
                  }}>
                    Add Financial
                  </Button>
                </div>

                <div className="mb-3 mt-3 txt-center" style={{ textAlign: "left" }}>
                  <PrimaryButtonComponent
                    loading={loading}
                    onSave={handleSave}
                    errors={errors}
                    keyName="termMainBtn"
                    currentRecord={currentRecord}
                  />
                  <ResetButtonComponent
                    keyName="rstBtn"
                    onClear={() => {
                      clearForm();
                    }}
                  />
                  {errorMessage && <p className="text-danger">{errorMessage}</p>}
                </div>
              </form>
            </div>
          </div>
          <AlertMessageComponent type={'info'} open={alertOpen} handleClose={handleAlert} message={errorMessage} />
        </section>

      </main>
    </div>
  );
};

export default CompanyProfile;
