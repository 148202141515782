"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";

import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";

import UserRequest from "../../Models/UserRequest";
import StatusRequestData from "../../Models/StatusRequest";
import { useNavigate, useParams } from "react-router-dom";

import TextFieldComponent from "../../components/form/TextFieldComponent";
import SelectFieldComponent from "../../components/form/SelectFieldComponent";
import CheckBoxFieldComponent from "../../components/form/CheckBoxFields";
import PrimaryButtonComponent from "../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../components/form/ResetButtonComponent";
import IdLabelData from "../../Models/IdLabelData";


const UsersForm: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  const [errorMesssage, setErrorMessage] = useState<any>(null);
  const empType = [{ id: "Full Time", label: "Full Time" }, { id: "Part Time", label: "Part Time" }, { id: "Casual", label: "Casual" }, { id: "Contractor", label: "Contractor" }];
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<IdLabelData[]>([]);
  const [contractorList, setContractorList] = useState<IdLabelData[]>([]);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [errors, setErrors] = useState({
    firstName: "",
    email: "",
    phone: "",
    lastName: "",
    empType: "",
    role: "",
    contractor: ""
  });

  const validateField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    switch (elementName) {
      case 'firstName':
        if (!userForm.firstName.trim()) {
          newErrors.firstName = "Firstname is required.";
        }
        break;
      case 'lastName':
        if (!userForm.lastName.trim()) {
          newErrors.lastName = "Lastname is required.";
        }
        break;
      case 'email':
        if (!element.value) {
          newErrors.email = "Email is required.";
        } else if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        } else {
          const isEmailExists = await checkEmail(element.value);
          console.log(isEmailExists);
          if (isEmailExists) {
            newErrors.email = "Email ID already Exists";
          } else {
            newErrors.email = '';
          }
        }
        break;

      case 'phone':
        if (!phoneRegex.test(userForm.phone) && userForm.phone) {
          newErrors.phone = "Invalid Australian phone number.";
        }
        break;
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }
  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!userForm.firstName.trim()) {
      newErrors.firstName = "Firstname is required.";
    }
    if (!userForm.lastName) {
      newErrors.lastName = "Lastname is required.";
    }
    // Email Validation
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userForm.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(userForm.email)) {
      newErrors.email = "Invalid email address.";
    }
    // Phone Number Validation
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    if (!phoneRegex.test(userForm.phone) && userForm.phone) {
      newErrors.phone = "Invalid Australian phone number.";
    }
    if (!userForm.empTypeTxt) {
      newErrors.empType = "Employee Type is required.";
    }
    if (userForm.roleTxt.length === 0) {
      newErrors.role = "Select at least one Role.";
    }
    if (userForm.roleTxt.indexOf('7') !== -1 && !userForm.contractor) {
      newErrors.contractor = "Contractor is required.";
    }

    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface userFormFields {
    firstName: string,
    lastName: string,
    email: string,
    id?: string | null,
    phone: string,
    empTypeTxt: string,
    roleTxt: any[],
    contractor: number
  }
  const [userForm, setUserForm] = useState<userFormFields>({
    firstName: "",
    lastName: "",
    email: "",
    id: null,
    phone: "",
    empTypeTxt: "Full Time",
    roleTxt: [],
    contractor: 0

  });

  useEffect(() => {
    if (currentId) {
      if (currentId !== 'Add') {
        fetchSingleRecord(currentId);
      }
    } else {
      setCurrentRecord(null);
    }
  }, [currentId]);



  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await HttpService.get(
          Constants.apiURL + Constants.rolesURL,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        if (response.success) {
          setRoles(
            response.roles.map((item: any) => ({
              id: item.id,
              label: item.title
            }))
          );


        } else {
          setRoles([])
        }
        console.log("GET Response:", response);
      } catch (error) {
        setRoles([])
        console.error("Error fetching data:", error);
      }
    };
    if (contractorList.length === 0 && isInitialLoad.current && isInitialLoad.current) {
      fetchContractors();
    }
    if (roles.length === 0 && isInitialLoad.current) {
      fetchRoles();
    }

    
    isInitialLoad.current = false;
  }, []);


  const Create = async (dataObj: UserRequest) => {
    try {
      const response = await HttpService.post(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
     
        clearForm(false);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const Update = async (dataObj: UserRequest, id: number) => {
    try {
      dataObj['id'] = id;
      const response = await HttpService.put(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        clearForm(false);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
  
        clearForm(false);
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const fetchContractors = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.contractorsURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: '', limit: -1, offset: 0 },
      );
      if (response.success) {
        setContractorList([
          { id: "", label: "Select Contractor" }, // Default option
          ...response.data.paginateRecords.map((item: any) => ({
            id: item.id,
            label: item.name,
          })),
        ]);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to Find Contractors. Please try again.");
      console.error("Error fetching data:", error);
    }
  }

  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.usersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.user);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const checkEmail = (email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = Constants.apiURL + Constants.usersURL + '/findByEmail/' + email;
        if (currentRecord) {
          url = url + '?userId=' + currentRecord.id
        }
        const response = await HttpService.get(
          url,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        return resolve(response.user);
      } catch (error) {
        return resolve(null);
      }
    })

  };
 
  

  const clearForm = (isForm: boolean) => {
  
    setUserForm({
      firstName: "",
      lastName: "",
      email: "",
      id: null,
      phone: "",
      empTypeTxt: '',
      roleTxt: [],
      contractor: 0

    });
    setCurrentRecord(null);
    setErrorMessage('');
    navigate('/management/users')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);
   
    setUserForm(
      {
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        id: record.id,
        phone: record.phone,
        empTypeTxt: record.empType,
        roleTxt: record.roleId.split(','),
        contractor: record.contractorId

      }
    );

  };
 
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    
  };

  const handleSave = async () => {
    if (validateForm()) {
      setLoading(true);
      const userReq: UserRequest = {
        email: userForm.email,
        empType: userForm.empTypeTxt,
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        phone: userForm.phone,
        roleId: Array.from(new Set(userForm.roleTxt)).join(","),
        contractorId: userForm.contractor
      };
      if (currentRecord) {
        await Update(userReq, currentRecord.id);
      } else {
        await Create(userReq);
      }

      setLoading(false);
    }

  };
  const hanldeUserFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;
    setUserForm((prev: any) => {
      // Check if the input is a checkbox
      if (type === "checkbox") {
        const target: any = e.target;
        const currentValues = prev[name] || []; // Get the current array or initialize it
        console.log(JSON.stringify(currentValues), '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
        if (target.checked && currentValues.includes(value) === false) {
          validateField(e.target);
          return { ...prev, [name]: [...currentValues, value] };
        } else {
          validateField(e.target);
          return { ...prev, [name]: currentValues.filter((id: any) => id !== value) };
        }
      } else {
        validateField(e.target);
        // Handle other input types
        return { ...prev, [name]: value };
      }
    });
  };

  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "User Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
                <div className="formElement">
                  <div className="row formheader">
                    <div className="col">
                      <h1 className="pageheader">Profile</h1>
                    </div>
                  </div>

                  <form className="container loginform">
                    <TextFieldComponent
                      label="First Name"
                      keyName="firstNameForm"
                      name="firstName"
                      value={userForm.firstName}
                      onChange={hanldeUserFormChange}
                      errorStr={errors.firstName}
                      isRequired={true}
                    />
                    <TextFieldComponent
                      label="Last Name"
                      keyName="lastNameForm"
                      name="lastName"
                      value={userForm.lastName}
                      onChange={hanldeUserFormChange}
                      errorStr={errors.lastName}
                      isRequired={true}
                    />
                    <TextFieldComponent
                      label="Email ID"
                      keyName="emailForm"
                      name="email"
                      value={userForm.email}
                      onChange={hanldeUserFormChange}
                      errorStr={errors.email}
                      placeholder="yourname@company.com"
                      isRequired={true}
                    />
                    <TextFieldComponent
                      label="Phone Number"
                      keyName="phoneForm"
                      name="phone"
                      value={userForm.phone}
                      onChange={hanldeUserFormChange}
                      errorStr={errors.phone}
                      placeholder=""
                      isRequired={false}
                    />
                    <SelectFieldComponent
                      label="Employee Type"
                      isRequired={true}
                      items={empType}
                      keyName="empTypeTxtForm"
                      name="empTypeTxt"
                      value={userForm.empTypeTxt}
                      onChange={(e: any) => {
                        hanldeUserFormChange(e);
                      }}
                      errorStr={errors.empType}
                    />
                    <CheckBoxFieldComponent
                      checkField={userForm.roleTxt}
                      label="User Roles"
                      errorStr={errors.role}
                      onCheckBoxChange={hanldeUserFormChange}
                      isRequired={false}
                      items={roles}
                      keyName="roleField"
                      name="roleTxt"

                    />
                    {userForm.roleTxt.indexOf("7") !== -1 && (
                      <SelectFieldComponent
                        label="Contractor"
                        name="contractor"
                        keyName="contractorSelect"
                        value={userForm.contractor.toString()}
                        onChange={(e: any) => {
                          hanldeUserFormChange(e);
                        }}
                        isRequired={true}
                        errorStr={errors.contractor}
                        items={contractorList}
                      />
                    )}

                    <div
                      className="mb-3 mt-3 txt-center"
                      style={{ textAlign: "left" }}
                    >
                      <PrimaryButtonComponent
                        keyName={"createbtn"}
                        errors={errors}
                        onSave={handleSave}
                        loading={loading}
                      />
                      <ResetButtonComponent keyName="resetbtn" onClear={(isClear) => {
                        clearForm(isClear)
                      }} />

                      {errorMesssage && (
                        <p className="text-danger">{errorMesssage}</p>
                      )}
                    </div>
                  </form>
                </div>
             
            </div>
          </div>
        </section>
        
      </main>
    </div>
  );
};

export default UsersForm;
