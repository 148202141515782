
import { Alert, AlertColor, Snackbar } from '@mui/material';
interface AlertMessageComponentProps {
  handleClose: (
      event: any
    ) => void;
    open: boolean;
    message: string;
    type: AlertColor;
  }
  const AlertMessageComponent: React.FC<AlertMessageComponentProps> = ({ handleClose,open,message,type }) => {
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
    <Alert
      onClose={handleClose}
      severity={type}
      variant="filled"
      sx={{ width: '100%' }}
    >
      {message}
    </Alert>
  </Snackbar>
  );
}
export default AlertMessageComponent;