"use client";
import React, { useEffect, useState } from "react";
import "../Index.css";
import SideBar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import Constants from "../../../utils/contants";
import HttpService from "../../../services/HttpService";
import { useStore } from "../../../utils/store";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";

import TextFieldAreaComponent from "../../../components/form/TextFieldAreaComponent";
import PrimaryButtonComponent from "../../../components/form/PrimaryButtonComponent";
import ResetButtonComponent from "../../../components/form/ResetButtonComponent";

const StandardTermsForm: React.FC = () => {
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [termsForm, setTermsForm] = useState({ termText: "", id: null });
  const [errors, setErrors] = useState({
    termText: ""
  });

 

  // Fetch single term record
  const fetchSingleRecord = async (id: string) => {
    try {
      const response = await HttpService.get(
        `${Constants.apiURL + Constants.termsURL}/${id}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.success) {
        handleRowClick(response.terms);
      } else {
        setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch record. Please try again.");
    }
  };
  // Fetch data when component loads or dependencies change
  useEffect(() => {
    if (currentId) {
      if (currentId !== "Add") {
        fetchSingleRecord(currentId);
      } 
    } else {
     
      setCurrentRecord(null);
    }
  }, [currentId]);




  // Handle form input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setTermsForm((prevData) => ({ ...prevData, [name]: value }));
    validateField(e.target);

  };
  const validateField = async (element: any) => {
    const newErrors: any = {};
    if (!element.value.trim()) {
      newErrors.termText = "Text is required.";
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }

  // Handle record creation or update
  const handleSave = async () => {
    setLoading(true);
    const reqData: any = termsForm;
    const endpoint =
      `${Constants.apiURL + Constants.termsURL}`
    let response: any;
    try {
      if (currentRecord) {
        response = await HttpService.put(endpoint, reqData, {
          headers: { Authorization: `Bearer ${getAdmin()}` },
        });
      } else {
        response = await HttpService.post(endpoint, reqData, {
          headers: { Authorization: `Bearer ${getAdmin()}` },
        });
      }

      if (response.success) {
       
        clearForm(false);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    } finally {
      setLoading(false);
    }
  };

 
  // Clear form and reset state
  const clearForm = (isForm: boolean) => {
    setTermsForm({ termText: "", id: null });
    setErrorMessage("");
    setCurrentRecord(null);
    navigate("/management/terms");
  };

  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setTermsForm({ termText: record.termText, id: record.id });
    
  };


  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <div>
      <Header onSearch={handleSearchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Standard Terms & Conditions"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
            
            </div>
              <div className="formElement">
                <form className="container loginform">
                  <TextFieldAreaComponent
                    name="termText"
                    value={termsForm.termText}
                    onChange={handleInputChange}
                    minRows={7}
                    keyName="termTextForm"
                    errorStr={errors.termText}
                    isRequired={true}
                    label="Text"
                  />


                  <div className="mb-3 mt-3 txt-center" style={{ textAlign: "left" }}>
                    <PrimaryButtonComponent
                      loading={loading}
                      onSave={handleSave}
                      errors={errors}
                      keyName="termMainBtn"
                      currentRecord={currentRecord}
                    />
                    <ResetButtonComponent
                      keyName="rstBtn"
                      onClear={() => {
                        clearForm(false);
                      }}
                    />
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                  </div>
                </form>
              </div>
           
          </div>
        </section>
        
      </main>
    </div>
  );
};

export default StandardTermsForm;
