import DashboardIcon from '@mui/icons-material/Dashboard';
import { SiNintendogamecube } from "react-icons/si";
import DnsIcon from '@mui/icons-material/Dns';
import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { LoumcMenuItems } from '../utils/contants';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InventoryIcon from '@mui/icons-material/Inventory';
const SideBar: React.FC = () => {

  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const isActiveFlexible = (path: string) => location.pathname.indexOf(path) !== -1;
  var currentModule = location.pathname.split("/");
  var activeMenuItem = '';
  if(currentModule.length > 1){
    activeMenuItem = currentModule[1];
  }
  const [activeMenu, setActiveMenu] = useState(activeMenuItem);

  const toggleMenu = (menu: any) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? "" : menu));
  };
console.log(location);


  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <div>
          <li className="nav-item active"  >
            <Link className="nav-link collapsed" to="/quote" style={isActive('/quote') ? styles.activeLink : styles.link} >
              <DashboardIcon />
              <span className="ms-2">Quote Dashboard</span>
            </Link>
          </li>
          <li className="nav-item active"  >
            <Link className="nav-link collapsed" to="/project-dashboard" style={isActive('/project-dashboard') ? styles.activeLink : styles.link} >
              <AccountTreeIcon />
              <span className="ms-2">Project Dashboard</span>
            </Link>
          </li>
          <li className="nav-item active"  >
            <Link className="nav-link collapsed" to="/scheduling" style={isActive('/scheduling') ? styles.activeLink : styles.link} >
              <CalendarMonthIcon />
              <span className="ms-2">Scheduling</span>
            </Link>
          </li>
          <li className="nav-item active"  >
            <Link className="nav-link collapsed" to="/inventory" style={isActive('/inventory') ? styles.activeLink : styles.link} >
              <InventoryIcon />
              <span className="ms-2">Inventory</span>
            </Link>
          </li>
          
          <li className="nav-item">
            <div className="nav-link collapsed" onClick={() => toggleMenu("management")}

              style={isActiveFlexible('/management') ? styles.activeLink : styles.link} >
              <div className="innerSidebar">
                <div className="first">
                  <DnsIcon />
                  <span className="ms-2">Data Management</span>
                </div>
                <div className="second">
                  {activeMenu === "management" ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>


            </div >
            {activeMenu === "management" && (
              <ul style={styles.dropdown}>
                {
                  LoumcMenuItems.management.map((item: any)=>{
                    return <li>
                  <Link
                    className={isActive(item.link) ? 'nav-link nav-link-active' : 'nav-link'}
                    to={item.link}
                    style={styles.link}

                  >
                   {item.icon} {item.label}
                  </Link>
                </li>
                  })
                }
                
          

              </ul>
            )}
          </li>
          

        </div>


      </ul>

    </aside>

  );
};
const styles = {
  nav: {
    display: 'flex',
    gap: '20px',
    padding: '10px',
   


  },
  link: {
    textDecoration: 'none',
    display: 'flex',

    // color: 'black',
  },
  activeLink: {
    textDecoration: 'none',
    color: '#000B29',
    fontWeight: 'bold',
    backgroundColor: '#aaaaaa',
    borderLeft: '2px solid #000B29'
  },
  dropdown: {
    paddingLeft: "20px",
    display: "block",
  },
};
export default SideBar;