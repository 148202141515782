interface PrimaryButtonComponentProps {
    onSave: (
    ) => void;
    loading: boolean;
    errors: any;
    currentRecord?: any;
    keyName: string
  }
  const PrimaryButtonComponent: React.FC<PrimaryButtonComponentProps> = ({ onSave,loading,errors,currentRecord,keyName }) => {
  return (
    <button
    type="button"
    id={keyName}
    key={keyName}
    className={"btn btn-primary  createbtn"}
    disabled={loading || Object.keys(errors).length > 0}
    onClick={() => {
      onSave();
    }}
  >
    {loading
      ? "Adding wait..."
      : currentRecord
        ? "Update"
        : "Create"}
  </button>
  );
}
export default PrimaryButtonComponent;