"use client";
import React, { useEffect, useState } from "react";

import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Fab,
  Paper
} from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import CustomPaginationActionsTable from "../../components/TablePagination";
import { Add } from "@mui/icons-material";
import SwitchComponent from "../../components/SwitchComponent";

const Project: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Columns definition for the table
  const columns: any[] = [
    { field: "name", headerName: "Project Template Name" },
    { field: "cost", headerName: "Price" },
    { field: "notes", headerName: "Notes" },
    {
      field: "updated",
      headerName: "Last Updated",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    { field: "creator", headerName: "User" },
    {
      field: "status",
      headerName: "Active",
      renderCell: (value: any) => (
        <SwitchComponent
          checked={value === "1"}
          value={value}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
          handleInputChange={() => console.log('clickstatus')}
          name={'statuseterm'}
        />
      ),
    },
  ];

  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [paginate, setPaginate] = useState({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });

  // Fetch  list
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.projectsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };




  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);
  // Handle status toggle
  const handleStatus = async (row: any) => {
    const newStatus = row.status === "1" ? "0" : "1";
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.projectsURL,
        { status: newStatus, id: row.id },
        { headers: { Authorization: `Bearer ${getAdmin()}` } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  // Clear form and reset state


  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <div>
      <Header onSearch={handleSearchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Project Templates"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
              <Paper sx={{ width: "100%" }} className="mt-4">
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={(row) => navigate(`/management/projects/${row.id}`)}
                  paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                  handleStatus={handleStatus}
                />
              </Paper>
            </div>
          </div>
        </section>
        <Fab
          aria-label="add"
          sx={fabStyle}
          onClick={() => navigate("/management/projects/Add")}
        >
          <Add />
        </Fab>
      </main>
    </div>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default Project;
