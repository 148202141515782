"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Fab, Paper } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";

import CustomPaginationActionsTable from "../../components/TablePagination";

import StatusRequestData from "../../Models/StatusRequest";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import SwitchComponent from "../../components/SwitchComponent";
const Users: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  const columns: any[] = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email Address", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "empType", headerName: "Employee Type", flex: 1 },
    {
      field: "contractorName", headerName: "Contractor Name", flex: 1, valueGetter: (value: any, row: any) => {
        return (!value) ? 'N/A' : value;
      },
    },
    {
      field: "roleId",
      width: 200,
      headerName: "User Role",
      valueGetter: (value: any, row: any) => {
        return row.roleNames;
      },
    },
    {
      field: "status",
      width: 100,
      headerName: "Active",
      renderCell: (value: any, row: any) => (
        <SwitchComponent
          checked={value === "1" ? true : false}
          size="small"
          color={"success"}
          inputProps={{ "aria-label": "controlled" }}
          handleInputChange={()=>{}}
          name={'userStatusswitch'}
          value={value}
        />
      ),
    },
    { field: "statusInfo", headerName: "Confirmation Status", flex: 1 },
    {
      field: "updated",
      flex: 1,
      headerName: "Date Modified",
      valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState<any>("");
  const [paginate, setPaginate] = useState<any>({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errorMesssage, setErrorMessage] = useState<any>(null);

  const [rows, setRows] = useState<any>([]);
  
  useEffect(() => {
    if ((searchQuery || paginate) || isInitialLoad.current) {
      fetchData();
    }
    isInitialLoad.current = false;
  }, [searchQuery, paginate]);
  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.usersURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);

        setTotalRecords(response.data.total);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const clearForm = (isForm: boolean) => {
   
   
    setErrorMessage('');
    navigate('/management/users')
  };
  
  const handlePagination = (paginationData: any) => {
    console.log(paginationData);
    setPaginate(paginationData);
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    setSearchQuery(value);
  };
  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "User Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
            
                <Paper sx={{ width: "100%" }} className="mt-4">
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) => {
                      navigate('/management/users/' + row.id)
                      //console.log(data);
                    }}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
            </div>
          </div>
        </section>
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() => {
              navigate('/management/users/Add');
            }}
          >
            <Add />
          </Fab>
      </main>
    </div>
  );
};
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};
export default Users;
