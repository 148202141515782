import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StoreProvider } from './utils/store';

import AppRoutes from './AppRoutes';
const theme = createTheme({
  palette: {
    secondary: {
      main: 'rgb(1, 1, 1)', 
      dark:'rgba(0, 244, 255, 0.08)'
      
    },
    primary: {
      main: 'rgba(0, 244, 255, 0.08)',
      dark: 'rgb(1, 1, 1)'
    
    },
    
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <StoreProvider>
          <AppRoutes />
        </StoreProvider>
      </Router>
    </ThemeProvider>

  );
};

export default App;
