"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Fab,
  Paper,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import CustomPaginationActionsTable from "../../components/TablePagination";
import StatusRequestData from "../../Models/StatusRequest";
import SwitchComponent from "../../components/SwitchComponent";
import { useNavigate } from "react-router-dom";

const Contractor: React.FC = () => {
  const { getAdmin } = useStore();
  const navigate = useNavigate();
  // Table Columns Definition
  const columns: any[] = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "email", headerName: "Email Address", width: 200 },
    { field: "phone", headerName: "Phone Number", width: 130 },
    { field: "creditLimit", headerName: "Credit Limit", width: 130 },
    { field: "contractorID", headerName: "Contractor ID", width: 130 },
    { field: "abn", headerName: "ABN Number", width: 150 },
    {
      field: "status",
      headerName: "Active",
      width: 130,
      renderCell: (value: any) => (

        <SwitchComponent
          checked={value === "1" ? true : false}
          size="small"
          color={"success"}
          inputProps={{ "aria-label": "controlled" }}
          handleInputChange={() => { }}
          name={'Statusswitch'}
          value={value} />
      ),
    },
    {
      field: "updated",
      headerName: "Date Modified",
      width: 130,
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
  ];
  // State Management
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  const [paginate, setPaginate] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    offset: 0,
  });
  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);
  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.contractorsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset }
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };










  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.contractorsURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };




  return (
    <div>
      <Header onSearch={(e) => setSearchQuery(e.target.value)} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Contractor Management"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            {(
              <Paper sx={{ width: "100%" }} className="mt-4">
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={(row: any) => {
                    navigate('/management/contractors/' + row.id)
                  }}
                  handleStatus={handleStatus}
                  paginationModel={{
                    page: paginate.offset,
                    pageSize: paginate.limit,
                  }}
                />
              </Paper>
            )
            }
          </div>
        </section>
        {(
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() =>
              navigate('/management/contractors/Add')
            }
          >
            <Add />
          </Fab>
        )}
      </main>
    </div>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default Contractor;
