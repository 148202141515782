import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import { useStore } from "../../utils/store";
import { Box, Button, Paper, Step, StepLabel, Stepper } from '@mui/material';
import Grid from "@mui/material/Grid2";
import SelectFieldComponent from '../../components/form/SelectFieldComponent';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextFieldAreaComponent from '../../components/form/TextFieldAreaComponent';
import DragAndDropFileUploadWithBrowse from '../../components/FileUploadComponentWithBrowse';
import Constants, { getCurrentDate } from '../../utils/contants';

import HttpService from '../../services/HttpService';

import SimpleAutoCompleteTextFieldComponent from '../../components/form/SimpleAutoCompleteTextFieldComponent';

interface QuoteDialogComponentComponentProps {
    handleClose: (
    ) => void;
    open: boolean;

}


const QuoteDialogComponent: React.FC<QuoteDialogComponentComponentProps> = ({ handleClose, open }) => {
    const steps = [
        'Details',
        'Documents',
        'Pricing',
        'T & C',
        'Review'
    ];

    const { getAdmin } = useStore();
    const [activeStep, setActiveStep] = React.useState(0);
    const [quoteStatusList, setQuoteStatusList] = React.useState<any[]>([]);
    const [inductionList, setInductionList] = React.useState<any[]>([]);
    const [quoteActionList, setQuoteActionList] = React.useState<any[]>([]);
    const [customerList, setCustomerList] = React.useState<any[]>([]);
    const [currentCustomer, setCurrentCustomer] = React.useState<any>(null);
    const [currentProject, setCurrentProject] = React.useState<any>(null);
    const [projectTemplateList, setProjectTemplateList] = React.useState<any[]>([]);
    const [errorMesssage, setErrorMessage] = React.useState<any>(null);
    const [fileQuoteMedia, setFileQuoteMedia] = React.useState<any>([]);
    const maxSteps = steps.length;

    const [detailForm, setDetailForm] = React.useState<any>({
        quoteStatusId: "",
        quoteActionId: "",
        customerId: '',
        customerEmail: '',
        mainContact: '',
        contactNumber: '',
        id: null,
        projectName: "",
        projectWorkCategory: '',
        projectSiteAddress: '',
        estimateStartDate: getCurrentDate(),
        submissionDueDate: getCurrentDate(),
        validToDate: getCurrentDate(),
        quoteInductionId: '',
        projectTemplateId: '',
        internalNotes: ''

    });
    const fetchStatusList = async () => {
        try {
            const statusList: any = await fetchBundle('fetchQuoteStatuses');
            if (statusList) {
                setQuoteStatusList([{ id: '', name: 'Select Status' }, ...statusList]);

                setDetailForm((prev: any) => {
                    return { ...prev, ['quoteStatusId']: statusList[0].id.toString() };

                });

            } else {
                setErrorMessage(statusList);
            }
        } catch (error) {
            setErrorMessage(error);
            console.error(error);
        }
    }
    const fetchInductionList = async () => {
        try {
            const inductionListData: any = await fetchBundle('fetchQuoteInductions');
            if (inductionListData) {
                setInductionList([{ id: '', name: 'Select Induction' }, ...inductionListData]);
            } else {
                setErrorMessage(inductionListData);
            }
        } catch (error) {
            setErrorMessage(error);
            console.error(error);
        }
    }
    const fetchListData = async (type: string) => {

        try {
            let URL: string = '';
            if (type == 'customer') {
                URL = `${Constants.apiURL}${Constants.customersURL}`;
            }
            if (type == 'project') {
                URL = `${Constants.apiURL}${Constants.projectsURL}`;
            }
            const response = await HttpService.get(
                URL,
                { headers: { Authorization: "Bearer " + getAdmin() } },
                { search: '', limit: -1, offset: 0 },
            );
            if (response.success) {
                if (type == 'customer') {
                    setCustomerList(response.data.paginateRecords);
                }
                if (type == 'project') {
                    setProjectTemplateList(response.data.paginateRecords)
                }

            } else {
                setErrorMessage('Failed to Fetch List. Please try again.');
            }
            console.log("GET Response:", response);
        } catch (error) {
            setErrorMessage("Failed to Find List. Please try again.");
            console.error("Error fetching data:", error);
        }


    }
    const fetchBundle = async (type: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                let queryParams = '';
               

               
                const URL = `${Constants.apiURL}${Constants.refBundleURL}/${type}`;
                const response = await HttpService.get(
                    URL,
                    { headers: { Authorization: "Bearer " + getAdmin() } },
                    { search: '', limit: -1, offset: 0,quoteStatusId:detailForm.quoteStatusId  },
                );
                if (response.success) {
                    resolve(response.data)
                } else {
                    reject('Failed to Fetch List. Please try again.');
                }
                console.log("GET Response:", response);
            } catch (error) {
                reject("Failed to Find List. Please try again.");
                console.error("Error fetching data:", error);
            }
        })

    }
    React.useEffect(() => {
        if (quoteStatusList.length === 0)
            fetchStatusList();
        if (inductionList.length === 0)
            fetchInductionList();
        if (customerList.length === 0)
            fetchListData('customer');
        if (projectTemplateList.length === 0)
            fetchListData('project');
    }, []);
    React.useEffect(() => {
        if (detailForm.quoteStatusId) {
            fetchBundle('fetchQuoteActions').then((actionListData: any) => {
                if (actionListData) {
                    setQuoteActionList([{ id: '', name: 'Select Action' }, ...actionListData]);
                }
            });

        }
    }, [detailForm.quoteStatusId]);
    const handleNext = () => {
        console.log(detailForm);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const renderSteps = (step: number) => {
        switch (step) {
            case 0:
                return detailsStep();
                break;
            case 1:
                return FileUploadStep();
                break;
            default:
                break;
        }
    }

    const handleFileChange = (files: any) => { 
        setFileQuoteMedia(files)
    }

    const handleChangeDate = (value: any, name: string) => {
        const DateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
        setDetailForm((prev: any) => {
            return { ...prev, [name]: DateValue };
        });
    }
    const handleRemove = (file: any) => {

    }
    const handleAutoCompleteChange = (
         e: any,dataValue:any,typeData: string
    ) => {
        if(typeData === 'customerId'){
            setCurrentCustomer(dataValue)
            setDetailForm((prev: any) => ({
                ...prev,
                mainContact: dataValue.tradingName.toString(),
                customerEmail: dataValue.email,
                contactNumber: dataValue.phone,
                customerId: dataValue.id.toString()
            }));
            
        }
        if(typeData === 'projectTemplateId'){
            setCurrentProject(dataValue)
            setDetailForm((prev: any) => {
                return { ...prev, [typeData]: dataValue.id.toString() };
            });
        }
       
       
    };
    const handleDetailInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
    ) => {
        const { name, value } = e.target;
        setDetailForm((prev: any) => {
            return { ...prev, [name]: value };
        });
    };
    const FileUploadStep = () => {
        return (<Grid container spacing={2} style={{ marginTop: "30px" }}>
            {/* Section Header */}
            <Grid size={12}>
                <h6 className="form-label">{'Documents'}</h6>
            </Grid>

            {/* Quote Status Select Field */}
            <Grid size={12}>
                <DragAndDropFileUploadWithBrowse
                    handleRemove={handleRemove}
                    handleCurrentFilesRemove={handleRemove}
                    errorStr={''} multiple={true}
                    label="Document & Image" isRequired={true} keyName="fileQuoteMedia"
                    name="fileQuoteMedia"
                    defaultValue={fileQuoteMedia}
                    onChange={handleFileChange} />
            </Grid>





        </Grid>
        );
    }
    const detailsStep = () => {
        return (<Grid container spacing={2} style={{ marginTop: "30px" }}>
            {/* Section Header */}
            <Grid size={12}>
                <h6 className="form-label">{'Details'}</h6>
            </Grid>

            {/* Quote Status Select Field */}
            <Grid size={6}>
                <SelectFieldComponent
                    label="Quote Status"
                    isRequired={false}
                    items={quoteStatusList}
                    keyName="quoteStatusId"
                    name="quoteStatusId"
                    value={detailForm.quoteStatusId}
                    onChange={handleDetailInputChange}
                    errorStr=""
                />
            </Grid>


            <Grid size={6}>
                <SelectFieldComponent
                    label="Action"
                    isRequired={false}
                    items={quoteActionList}
                    keyName="quoteActionId"
                    name="quoteActionId"
                    value={detailForm.quoteActionId}
                    onChange={handleDetailInputChange}
                    errorStr=""
                />
            </Grid>
            {/* Customer Sections Starts  */}
            <Grid size={12}>
                <h6 className="form-label">{'Customer Details'}</h6>
            </Grid>
            <Grid size={6}>

                <SimpleAutoCompleteTextFieldComponent
                    name="customer"
                    value={currentCustomer}
                    isRequired={false}
                    errorStr={''}
                    keyName="res-customer"
                    label="Customer"
                    onChange={(e: any, value: any) => {
                        handleAutoCompleteChange(e,value,'customerId');
                    }}
                    items={customerList}
                />
            </Grid>


            <Grid size={6}>
                <TextFieldComponent
                    name="customerEmail"
                    value={detailForm.customerEmail}
                    isRequired={false}
                    errorStr={''}
                    keyName="customerEmail"
                    label="Customer Email "
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>

            <Grid size={6}>
                <TextFieldComponent
                    name="mainContact"
                    value={detailForm.mainContact}
                    isRequired={false}
                    errorStr={''}

                    keyName="mainContact"
                    label="Main Contact "
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>


            <Grid size={6}>
                <TextFieldComponent
                    name="contactNumber"
                    value={detailForm.contactNumber}
                    isRequired={false}
                    errorStr={''}

                    keyName="contactNumber"
                    label="Main Contact Number "
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>

            {/* Customer Sections Ends  */}
            {/* Project  Sections Starts  */}
            <Grid size={12}>
                <h6 className="form-label">{'Project Details'}</h6>
            </Grid>



            <Grid size={6}>
                <TextFieldComponent
                    name="projectName"
                    value={detailForm.projectName}
                    isRequired={false}
                    errorStr={''}
                    keyName="projectName"
                    label="Project Name"
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>

            <Grid size={6}>
                <TextFieldComponent
                    name="projectWorkCategory"
                    value={detailForm.projectWorkCategory}
                    isRequired={false}
                    errorStr={''}

                    keyName="projectWorkCategory"
                    label="Category of work"
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>


            <Grid size={12}>
                <TextFieldComponent
                    name="projectSiteAddress"
                    value={detailForm.projectSiteAddress}
                    isRequired={false}
                    errorStr={''}

                    keyName="projectSiteAddress"
                    label="Site Address"
                    onChange={handleDetailInputChange}
                    placeholder=""
                />
            </Grid>
            <Grid size={6}>
                <DatePickerComponent
                    defaultValue={detailForm.estimateStartDate}
                    errorStr=''
                    isRequired={false}
                    keyName='estimateStartDate'
                    label='Estimated Start Date'
                    name='estimateStartDate'
                    onChange={(value: any) => {
                        handleChangeDate(value, 'estimateStartDate')
                    }}

                />
            </Grid>
            <Grid size={6}>
                <DatePickerComponent
                    defaultValue={detailForm.submissionDueDate}
                    errorStr=''
                    isRequired={false}
                    keyName='submissionDueDate'
                    label='Quote Submission Due Date'
                    name='submissionDueDate'
                    onChange={(value: any) => {
                        handleChangeDate(value, 'submissionDueDate')
                    }}

                />
            </Grid>
            <Grid size={6}>
                <DatePickerComponent
                    defaultValue={detailForm.quoteValidToDate}
                    errorStr=''
                    isRequired={false}
                    keyName='quoteValidToDate'
                    label='Quote Valid To'
                    name='quoteValidToDate'
                    onChange={(value: any) => {
                        handleChangeDate(value, 'quoteValidToDate')
                    }}

                />
            </Grid>
            <Grid size={6}>
                
                <SelectFieldComponent
                    label="Induction Required"
                    isRequired={false}
                    items={quoteStatusList}
                    keyName="quoteInductionId"
                    name="quoteInductionId"
                    value={detailForm.quoteInductionId}
                    onChange={handleDetailInputChange}
                    errorStr=""
                />
            </Grid>
            <Grid size={6}>

                <SimpleAutoCompleteTextFieldComponent
                    name="projectTemplateId"
                    value={currentProject}
                    isRequired={false}
                    errorStr={''}
                    keyName="projectTemplateId"
                    label="Project Templates"
                    onChange={(e: any, value: any) => {
                        handleAutoCompleteChange(e,value,'projectTemplateId');
                    }}
                    items={projectTemplateList}
                />
            </Grid>
            <Grid size={12}>
                <TextFieldAreaComponent
                    label="Internal Notes"
                    isRequired={false}
                    minRows={5}
                    keyName="internalNotes"
                    name="internalNotes"
                    value={detailForm.internalNotes}
                    onChange={handleDetailInputChange}
                    errorStr=""
                />
            </Grid>

            {/* Projject Sections Ends  */}
        </Grid>
        );
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <div style={{ width: '100%', margin: '20px' }}>
                <div onClick={handleClose} style={{ cursor: 'pointer' }} >
                    <UTurnLeftIcon style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} onClick={handleClose} />
                    Back
                </div>

                <Paper sx={{ margin: '50px', padding: '20px' }} className="mt-4">
                    <h6 key={'lbl_dialogList'} className="form-label">{'Add New Quote'}</h6>
                    <Box sx={{ padding: '10px', mb: 2, width: '60%', margin: '0 auto' }}>

                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>

                                </Step>

                            ))}



                        </Stepper>
                        <div>
                            {
                                renderSteps(activeStep)
                            }
                        </div>

                    </Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                        <Button variant="contained" onClick={handleBack} disabled={activeStep === 0 ? true : false}>
                            Back
                        </Button>
                        <Button color='secondary' variant="contained" onClick={handleNext} disabled={activeStep === (maxSteps - 1) ? true : false}>
                            Next
                        </Button>
                    </div>
                </Paper>

            </div>
        </Dialog>
    );
}
export default QuoteDialogComponent;


