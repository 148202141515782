

import { Switch, SwitchProps } from '@mui/material';
import styled from '@emotion/styled';

interface SwitchComponentProps {
  handleInputChange: (
      event: any
    ) => void;
    value: any;
    checked: boolean;
    name:any;
    size?: any;
    color?: any;
    inputProps?: any;
  }
  const SwitchComponent: React.FC<SwitchComponentProps> = ({ handleInputChange,checked,name,value,size,color,inputProps }) => {
  return (
   <IOSSwitch checked={checked} name={name} size={size} color={color} inputProps={inputProps} onChange={handleInputChange} value={value} />
  );
}
 const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: 35,
  height: 19,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:  '#009951',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#39393D',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity:  0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
   transition: 'background-color 0.5s ease'
  },
}));

export default SwitchComponent;